// import { useState } from "react"
import React from "react";

// MuiKit
import { Fab, Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { memo } from "react";

const theme = createTheme({
    palette: {
        forestCol: {
            main: "#ffffff", // #00e676
            dark: "#fcfbfd", // #4caf50
            contrastText: "#000",
        },
        speciesCol: {
            main: "#ffffff", // #ffe082
            dark: "#fcfbfd", // #ffca28
            contrastText: "#000",
        },
        climateCol: {
            main: "#ffffff", // #448aff
            dark: "#fcfbfd", // #039be5
            contrastText: "#000",
        },
        aspectCol: {
            main: "#ffffff", // #ff5252
            dark: "#fcfbfd", // #ba000d
            contrastText: "#000",
        },
    },
});

const Results = memo((props) => {
    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ marginBottom: "2%", padding: "1%" }}>
                <CardContent variant="outlined">

                    {/* Title container */}
                    <Stack direction="column" alignItems="flex-start">
                        <h2 style={{ lineHeight: 1.3, margin: "0", textAlign: "left" }}>
                            <span dangerouslySetInnerHTML={{ __html: props.title }}></span>
                        </h2>
                    </Stack>

                    {/* Author and source container */}
                    <Stack direction="column" alignItems="flex-start">
                        <div style={{ display: !isNaN(props.date) ? 'block' : 'none' }} className="date-container">
                            <p>
                                Published on: {props.date}
                            </p>
                        </div>
                        <Grid
                            direction="row"
                            alignItems="flex-end"
                            wrap="wrap"
                            container={true}
                        >
                            <h3 style={{ lineHeight: 1, margin: "20px 0 0", textAlign: "left" }}>
                                <b>Author</b>: &nbsp;
                            </h3>
                            <p style={{ margin: "0px" }}>{props.authors ? props.authors.includes('comma') ? props.authors.replace(/\s'comma'/g, ',') : props.authors : 'No recorded author'}</p>
                        </Grid >
                        <p style={{ lineHeight: 1.3, margin: "0", textAlign: "left", display: props.source ? 'block' : 'none' }}>
                            <b>Source</b>: <span dangerouslySetInnerHTML={{ __html: props.source }}></span>
                        </p>
                    </Stack>

                    {/* Badges container */}
                    <Grid
                        direction="row"
                        alignItems="center"
                        wrap="wrap"
                        spacing={2}
                        container={true}
                        margin="dense"
                        style={{ marginTop: "10px" }}
                    >
                        {/* Forest */}
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            wrap="wrap"
                            // spacing={2}
                            className={"forest-badges"}
                        >
                            {props.forest &&
                                props.forest.map((ft) => {
                                    return (
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="forestCol"
                                            aria-label="add"
                                            style={{ marginRight: "10px" }}
                                            key={ft}>

                                            {ft}
                                        </Fab>
                                    )
                                })}
                        </Stack>

                        {/* Species */}
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className={"species-badges"}
                        >
                            {props.species &&
                                props.species.map((ft) => {
                                    return (
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="speciesCol"
                                            style={{ marginRight: "10px" }}
                                            aria-label="add"
                                            key={ft}
                                        >
                                            {ft}
                                        </Fab>
                                    );
                                })}
                        </Stack>

                        {/* Climate */}
                        <Stack
                            direction="row"
                            wrap="wrap"
                            justifyContent="flex-start"
                            alignItems="center"
                            className={"climate-badges"}
                        >
                            {props.climate &&
                                props.climate.map((ft) => {
                                    return (
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="climateCol"
                                            style={{ marginRight: "10px" }}
                                            aria-label="add"
                                            key={ft}
                                        >
                                            {ft}
                                        </Fab>
                                    );
                                })}
                        </Stack>

                        {/* Aspect */}
                        <Stack
                            direction="row"
                            wrap="wrap"
                            justifyContent="flex-start"
                            alignItems="center"
                            className={"aspect-badges"}
                        >
                            {props.aspect &&
                                props.aspect.map((ft) => {
                                    return (
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="aspectCol"
                                            style={{ marginRight: "10px" }}
                                            aria-label="add"
                                            key={ft}
                                        >
                                            {ft}
                                        </Fab>
                                    );
                                })}
                        </Stack>
                    </Grid>

                </CardContent>
            </Card>
        </ThemeProvider>
    );
});

export default Results;
