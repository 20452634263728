import React, { useState, memo } from 'react';
import DropdownSelectUIK from '../DropdownSelect-uik';

import { postAPI } from '../../constants/consts';
import { useEffect } from 'react';
import { useRef } from 'react';
import Timeline from './Timeline';

// MuiKit
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Autocomplete, Divider, IconButton, Tooltip } from '@mui/material';

// icon
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { Grid } from '@mui/material';

const Search = memo((props) => {

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [keyword, setKeyword] = useState('');

    const [startYear, setStartYear] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState([]);

    const [forestType, setForestType] = useState([]);
    const [speciesType, setSpeciesType] = useState([]);
    const [climateType, setClimateType] = useState([]);
    const [aspect, setAspect] = useState([]);

    const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
    const [clearFilterRequested, setClearFilterRequested] = useState(false);
    const [clearTimeline, setClearTimeline] = useState(false); // Clearning the child state value

    const [yearRange, setYearRange] = useState([])

    const textInputValueRef = useRef();
    const submitButtonRef = useRef();

    const forestDropdownRef = useRef();
    const speciesDropdownRef = useRef();
    const climateDropdownRef = useRef();
    const aspectDropdownRef = useRef();


    // Search query object
    let searchQuery = {
        keyword: keyword,
        startYear: startYear,
        endYear: endYear,
        authors: selectedAuthor,
        forest_type: forestType,
        species_type: speciesType,
        climate_zone: climateType,
        aspect: aspect,
    }

    const refSearchQuery = {
        keyword: '',
        startYear: [],
        endYear: null,
        authors: null,
        forest_type: [],
        species_type: [],
        climate_zone: [],
        aspect: [],
    }

    // Fetching post once when the component loads  
    // setting refPosts: calling this once to avoid re-rendering of author list
    useEffect(() => {
        fetchPostHandler(postAPI).then(data => {
        }); // we are extracting authors and years as a part of this.
    }, [])


    // Getting the author data for dropdowns
    const extractAuthors = (data) => {

        // console.log("extact author", data)

        let authors_ = {};

        data.forEach(result => {

            // console.log(result.acf)
            
            const { authors } = result.acf;
            const id = result.id;

            /* if (!authors_[id]) {
                authors_[authors] = [id]
            } else {
                authors_[authors].push(id)
            } */

            // let all_authors = authors ? authors.replace(/,\./g, ',').split(",") : []
            let all_authors = authors ? authors.split(/,|&/) : []

            /* if (all_authors.length > 0) {
                 all_authors = return_authors_array(all_authors)
             } */

            if (all_authors.length > 0) {
                all_authors.forEach(a => {

                    let trimmed_author = a.trim()
                    if (!authors_[a]) {

                        if (a.includes("'comma'")) {
                            a = a.replace(/ 'comma'/g, ',');
                        }
                        authors_[a.trim()] = [id]
                    } else {
                        authors_[a.trim()].push(id)
                    }
                })
            }/*  */

        })

        const sorted_authors = Object.keys(authors_).sort((a, b) => a.localeCompare(b));
        setAuthors(sorted_authors)
    }

    // Extract all years
    const extractYearsFromResults = (data) => {

        let year_data = [];

        data.forEach(result => {
            const { year } = result.acf;

            if (year && year !== "undefined") {
                year_data.push(Number(year))
            }

        })

        if (year_data.length === 0) {
            return []
        }

        if (year_data.length === 1) {
            return [year_data[0]]
        }

        let earliestLatestDates = {
            "earliest": year_data[0],
            "lastest": year_data[1]
        }

        for (var i = 2; i < year_data.length; i++) {
            if (year_data[i] < earliestLatestDates.earliest) {
                earliestLatestDates.earliest = year_data[i]
            }

            if (year_data[i] > earliestLatestDates.lastest) {
                earliestLatestDates.lastest = year_data[i]
            }
        }

        setYearRange([Number(earliestLatestDates.earliest), Number(earliestLatestDates.lastest)])
    }

    // Async function to fetch data in chunks and process them
    const fetchDataInChunks = async (url, onChunkLoaded) => {
        let count = 1;
        do {
            let currentUrl = `${url}&page=${count}`;
            const response = await fetch(currentUrl);
            const newData = await response.json();

            // Immediately process and pass this chunk of data if not empty
            if (newData.length > 0) {
                onChunkLoaded(newData);
            }

            // Exit the loop if we've got less than 100 items, indicating we're at the end
            if (newData.length < 100) break;

            count += 1; // Prepare for the next page
        } while (true);
    };

    // Handler to fetch posts and process them in chunks
    const fetchPostHandler = async (api) => {

        props.loading(true);

        // Define what to do with each chunk of data
        const onChunkLoaded = (chunkData) => {
            // Pass data to parent component in chunks
            props.fetchingForParent(chunkData);

            // Extract authors and years from the current chunk
            extractAuthors(chunkData);
            extractYearsFromResults(chunkData);
            props.loading(false);
        };

        try {
            await fetchDataInChunks(api, onChunkLoaded);
        } catch (error) {
            console.error(error);
            // Handle error for the initial chunk
            props.fetchingForParent([]);
            extractAuthors([]);
            extractYearsFromResults([]);
        } finally {
            // Disable loading indicator after the first chunk is processed
            props.loading(false);
        }
    };



    // Building query object on change of dropdown elements
    const searchQueryBuilder = (type, selections) => {
        switch (type) {
            case "keyword":
                setKeyword(selections)
                break;
            case "authors":
                setSelectedAuthor(selections)
                break;
            case "forest_type":
                setForestType(selections)
                break;
            case "species_type":
                setSpeciesType(selections)
                break;
            case "climate_zone":
                setClimateType(selections)
                break;
            case "aspect":
                setAspect(selections)
                break;
            case "startYear":
                setStartYear(selections)
                break;
            case "endYear":
                setEndYear(selections)
                break;
            default:
                return false;
        }

        if (keyword.length > 0) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }

        searchQuery[type] = selections;         // query
    }

    // Search button press
    const submitFilterSearchQueryHandler = async (e) => {
        e.preventDefault();

        if (textInputValueRef.current.value) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }

        props.filterResults(searchQuery);

        props.resetPosts(true);

        // resetting_fields_and_data()
        // setClearTimeline(!clearTimeline)

    }

    // Clear filter handler
    const clearFilterHandler = async () => {

        props.resetPosts(true);

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const selectionCount = document.querySelectorAll('.selection-count');
        const dropdownElement = document.querySelectorAll(".dropdown-content");

        searchQuery = refSearchQuery;

        // start and end year
        const startEndYearCheckboxes = document.querySelectorAll('input[name*="year"]');
        startEndYearCheckboxes.forEach(yearList => {
            yearList.value = "";
        });

        checkboxes.forEach(cb => {
            cb.checked = false;
        })

        selectionCount.forEach(SC => {
            SC.innerHTML = "0";
        })

        dropdownElement.forEach(dd => {
            dd.classList.remove("d-flex", "flex-column")
            dd.classList.add("d-none")
        })

        setSelectedAuthor([])
        setForestType([])
        setSpeciesType([])
        setClimateType([])
        setAspect([])
        setStartYear(null)
        setEndYear(null)

        forestDropdownRef.current = null;
        speciesDropdownRef.current = null;
        climateDropdownRef.current = null;
        aspectDropdownRef.current = null;

        setShowAdvanceFilter(false);
        setButtonDisabled(true);

        props.resetPosts(false);

        textInputValueRef.current.value = null;

        resetCheckedCheckboxes()

    }

    const resetting_fields_and_data = () => {

        props.resetPosts(true);

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const selectionCount = document.querySelectorAll('.selection-count');
        const dropdownElement = document.querySelectorAll(".dropdown-content");

        searchQuery = refSearchQuery;

        // start and end year
        const startEndYearCheckboxes = document.querySelectorAll('input[name*="year"]');
        startEndYearCheckboxes.forEach(yearList => {
            yearList.value = "";
        });

        checkboxes.forEach(cb => {
            cb.checked = false;
        })

        selectionCount.forEach(SC => {
            SC.innerHTML = "0";
        })

        dropdownElement.forEach(dd => {
            dd.classList.remove("d-flex", "flex-column")
            dd.classList.add("d-none")
        })

        setSelectedAuthor([])
        setForestType([])
        setSpeciesType([])
        setClimateType([])
        setAspect([])
        setStartYear(null)
        setEndYear(null)

        forestDropdownRef.current = null;
        speciesDropdownRef.current = null;
        climateDropdownRef.current = null;
        aspectDropdownRef.current = null;

        setShowAdvanceFilter(false);
        setButtonDisabled(true);

        props.resetPosts(false);

        // textInputValueRef.current.value = null;
    }

    const resetCheckedCheckboxes = () => {
        setClearFilterRequested(true)
        setTimeout(() => {
            setClearFilterRequested(false)
        }, 500)
    }

    // Advance-filter toggle
    const showAdvanceFilterToggleHandler = () => {
        setShowAdvanceFilter(prevState => !prevState)
    }

    /* Helper functions
    document.addEventListener('keydown', (event) => {

        var name = event.key;
        var code = event.code;

        if (name === "Enter") {
            submitFilterSearchQueryHandler(event)
        }

    }, false); */

    const conditionalStyle = {
        display: showAdvanceFilter ? 'block' : 'none',
    }

    const handleAutocompleteChange = (event, newValue) => {
        searchQueryBuilder("authors", newValue);
        if (newValue !== null) {
        }
    };

    return (
        <Grid style={{ width: "95%", margin: "0 auto" }}>
            <Grid item={true}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                // margin
                padding
            >

                <Grid container
                    direction="row"
                    justifyContent="flex-start" item={true} xs={!showAdvanceFilter ? 9 : 12} md={!showAdvanceFilter ? 10 : 12} lg={!showAdvanceFilter ? 10 : 12}>
                    <TextField
                        // label="With normal TextField"
                        id="search-publications"
                        label="Search Publications..."
                        spellCheck="true"
                        inputRef={textInputValueRef}
                        sx={{ width: '100%' }}
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start"> <SearchIcon color="contrastText" /></InputAdornment>,
                        // }}
                        onKeyUp={(e) => searchQueryBuilder("keyword", textInputValueRef.current.value)}
                        autoComplete="off"
                    />
                </Grid>

                {/* Search and clear filter button */}
                {!showAdvanceFilter ?
                    <Grid container
                        direction="row"
                        justifyContent="center" item={true} xs={1} md={1}>
                        <IconButton type="submit" id="side-search" variant="contained" disabled={buttonDisabled} ref={submitButtonRef} onClick={submitFilterSearchQueryHandler}>
                            <SearchIcon color="contrastText" />
                        </IconButton>
                    </Grid> : ''}

                {!showAdvanceFilter ?

                    <Grid container
                        direction="row"
                        justifyContent="center" item={true} xs={1} md={1}>
                        {/* <IconButton id="side-clean-filter" disabled={buttonDisabled} onClick={clearTextFieldHandler} ><CleaningServicesIcon /></IconButton> */}
                        <Tooltip title="Reset results" placement="right">
                            <IconButton id="side-clean-filter" onClick={clearFilterHandler} ><CleaningServicesIcon /></IconButton></Tooltip>
                    </Grid> : ''}
            </Grid>

            <Divider
                sx={{ paddingTop: "2%", paddingBottom: "2%", }}>
                <Tooltip title={showAdvanceFilter ? "Close advanced filter" : "Expand advanced filter"} placement="bottom">
                    <IconButton id="expand-filter" onClick={showAdvanceFilterToggleHandler} variant="contained">
                        {!showAdvanceFilter ? <TuneIcon /> : <CloseIcon />}
                    </IconButton>
                </Tooltip>
            </Divider>

            {/* Clearing advanced filters */}
            {clearFilterRequested === false ? <div className='advanced-filter-dropdown' style={conditionalStyle}>

                <Grid item={true}
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ paddingBottom: "2%" }}
                >

                    <Grid item={true}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >

                        <Timeline startYear={yearRange[0]} endYear={yearRange[1]} selectValues={clearTimeline} queryYear={searchQueryBuilder}></Timeline>

                        <Grid container item={true} xs={12} md={4}>

                            {/* <FormControl fullWidth={true} sx={{ m: 0.5 }}>
                                <InputLabel id="author-dropdown-label">Author</InputLabel>
                                <Select
                                    labelId="author-dropdown-label"
                                    id="author-dropdown"
                                    label="Author"
                                    value={selectedAuthor}
                                    onChange={(e) => searchQueryBuilder("authors", e.target.value)}
                                >
                                    {authors && authors.map((val, i) => {
                                        return <MenuItem key={i} value={val}>{val}</MenuItem>
                                    })}
                                </Select>
                            </FormControl> */}

                            <FormControl fullWidth={true} sx={{ m: 0.5 }}>
                                <Autocomplete
                                    options={authors}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className='autoComplete-textField'
                                            label="Authors"
                                            variant="outlined"
                                        // Set readOnly to make it uneditable
                                        />
                                    )}
                                    onChange={handleAutocompleteChange}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid item={true}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        style={{
                            paddingTop: "2%"
                        }}>

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={forestDropdownRef}
                                dropdowns={["Planted", "Natural"]}
                                title="Forest Type"
                                dataType="forest_type"
                                parentDropdownDataHandler={searchQueryBuilder}
                                className="Forest Type Classname"
                            />

                        </Grid>

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={speciesDropdownRef}
                                dropdowns={[
                                    "Softwood",
                                    "Hardwood"
                                ]}
                                title="Species type"
                                dataType="species_type"
                                parentDropdownDataHandler={searchQueryBuilder} />
                        </Grid>

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={climateDropdownRef}
                                dropdowns={["Equatorial", "Tropical", "Sub-tropical", "Temperate", "Grasslands", "Desert"
                                ]} title="Climatic zone"
                                dataType="climate_zone"
                                parentDropdownDataHandler={searchQueryBuilder} />
                        </Grid>

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={aspectDropdownRef}
                                dropdowns={["Establishment",
                                    "Maintenance",
                                    "Harvest & haul",
                                    "Processing",
                                    "Markets",
                                    "Health",
                                    "Investment",
                                    "Environmental services",
                                ]} title="Aspect"
                                dataType="aspect"
                                parentDropdownDataHandler={searchQueryBuilder} />
                        </Grid>

                        <Grid spacing={0} container item={true}>

                        </Grid>

                    </Grid>

                    <Grid item={true}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding
                    >
                        <Button style={{ padding: "10px" }} color="error" variant="outlined" onClick={clearFilterHandler}>Clear filter <CleaningServicesIcon style={{ marginLeft: "10px" }} /></Button>

                        <Button style={{ padding: "10px" }} color="success" variant="outlined" onClick={submitFilterSearchQueryHandler}>Search <SearchIcon style={{ marginLeft: "10px" }} /></Button>
                    </Grid>
                </Grid>
            </div> : ''}


        </Grid>

    )
})

export default Search;