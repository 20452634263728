// export const postAPI = "https://fwpa.com.au/api/fwpa/get_reports";
// export let postAPI = "http://api.fwpa.eskspatial.com.au/fwpa-api/";
// postAPI = "https://fwpa.com.au/api/fwpa/get_reports"
// postAPI = "http://box2149.temp.domains/~savidula/fwpa/wp-json/wp/v2/posts"
// postAPI = "https://eskspatial.com.au/wp-json/wp/v2/posts"
// postAPI = "https://fwpa.com.au/api/wp/v2/posts?per_page=9999&categories=4289"
export let postAPI = "https://fwpa.com.au/wp-json/wp/v2/posts?categories=1126&per_page=100"

export let globalStateData = [];
export const setGlobalStateData = (data) => {
    globalStateData = data
}