import React, { useEffect } from 'react';
import Search from "./filters/Search"
import Results from './results/Results';
import LoadingSpinner from './LoadingSpinner';
import { useState } from 'react';
import Fuse from "fuse.js";
import { Button, Container, Grid } from '@mui/material';

const Filter = () => {

    const [posts, setPosts] = useState([]);
    const [refPosts, setRefPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(false);
    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
    const [numberOfResultsShowing, setNumberOfResultsShowing] = useState(25);

    let showingResults = 25;

    // Effect used for showing and hiding scroll to top.
    useEffect(() => {
        document.addEventListener('scroll', (e) => {
            const elem = document.getElementById('main-results-displayed-container');

            const totalOffSet = Number((elem.getBoundingClientRect().top + window.scrollY).toFixed(0));
            const distanceFromTopScrolled = Number((elem.getBoundingClientRect().top).toFixed(0));


            if (distanceFromTopScrolled <= (totalOffSet - 600) && showScrollToTopButton === false) {
                setShowScrollToTopButton(true);
            }

            if (distanceFromTopScrolled === totalOffSet) {
                setShowScrollToTopButton(false);
            }

        })
    }, [])

    // Sorting authors based on names
    const return_authors_string = (author_string) => {
        var tempElement = document.createElement('div');
        tempElement.innerHTML = author_string;
        return tempElement.innerText;
    }

    const ftsOptions = {
        // isCaseSensitive: true,
        // includeScore: true,
        // includeMatches: true,
        // minMatchCharLength: 3,
        // findAllMatches: true, // When true, the matching function will continue to the end of a search pattern even if a perfect match has already been located in the string
        // sortFn: (a, b) => a.score < b.score,
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: false,
        keys: ['title.rendered', 'acf.authors', 'acf.source', 'acf.species_type', 'acf.forest_type', 'acf.climate_zone', 'acf.aspect'],
    }

    // Full text search
    const fuse = new Fuse(posts, ftsOptions);

    const setFetchedPostData = (newData) => {
        setPosts(prevPosts => {
            const updatedPosts = [...prevPosts, ...newData].reduce((acc, current) => {
                const x = acc.find(item => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            return updatedPosts;
        });

        setRefPosts(prevRefPosts => {
            const updatedRefPosts = [...prevRefPosts, ...newData].reduce((acc, current) => {
                const x = acc.find(item => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            return updatedRefPosts;
        });
    };

    const setPostsLoading = (boolVal) => {
        setLoadingPosts(boolVal)
    }

    /* ACTUAL Search filter
    const validResultSortingHandler = (obj, element) => {

        // Building the searchQuery
        let validResult = true;

        const post_acf_data = element.acf

        if (Object.keys(post_acf_data).length == 0) {
            return false
        } else {

            const { year, authors, forest_type, species_type, climate_zone, aspect, date } = post_acf_data;

            // We check one queryObject property at a time
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {

                    const el = obj[key]; // startYear, endYear

                    if (key === "startYear") {

                        let postStartDate = Number(year); // startYear of the post || post_acf_data.

                        if (!el || isNaN(postStartDate) || Number(el) > postStartDate) {
                            validResult = false;
                            break;                           // exits the loop early
                        }
                    }

                    if (key === "endYear") {
                        let postEndDate = Number(year);

                        if (postEndDate) {
                            if (!el || isNaN(postEndDate) || Number(el) < postEndDate) {
                                validResult = false;
                                break;                         // exits the loop early
                            }
                        }
                    }

                    if (key === "forest_type") {
                        const stringifiedForestType = forest_type.length > 0 ? forest_type.join() : forest_type

                        // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                        if (el.length > 0) {
                            el.every(e => {
                                if (!stringifiedForestType.includes(e)) {
                                    validResult = false
                                    return false;            // exits the loop early
                                }
                            })
                        } else {
                            break                           // exits the loop early
                        }


                        if (!validResult) {
                            break            // exits the loop early
                        }
                    }

                    if (key === "species_type") {

                        const stringifiedSpeciesType = species_type.length > 0 ? species_type.join() : species_type

                        // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                        if (el.length > 0) {
                            el.every(e => {
                                if (!stringifiedSpeciesType.includes(e)) {
                                    validResult = false
                                    return false;            // exits the loop early
                                }
                            })
                        } else {
                            break            // exits the loop early
                        }

                        if (!validResult) {
                            break            // exits the loop early
                        }
                    }

                    if (key === "climate_zone") {

                        const stringifiedClimateZone = climate_zone.length > 0 ? climate_zone.join() : climate_zone

                        // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                        if (el.length > 0) {
                            el.every(e => {
                                if (!stringifiedClimateZone.includes(e)) {
                                    validResult = false
                                    return false;            // exits the loop early
                                }
                            })
                        } else {
                            break            // exits the loop early
                        }


                        if (!validResult) {
                            break            // exits the loop early
                        }
                    }

                    if (key === "aspect") {
                        const stringifiedAspect = aspect.length > 0 ? aspect.join() : aspect

                        // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                        if (el.length > 0) {
                            el.every(e => {
                                if (!stringifiedAspect.includes(e)) {
                                    validResult = false
                                    return false;            // exits the loop early
                                }
                            })
                        } else {
                            break            // exits the loop early
                        }


                        if (!validResult) {
                            break            // exits the loop early
                        }
                    }

                    if (key === "authors") {
                        // if (el !== authors) {

                        let ath = authors
                        if (authors.includes('comma')) {
                            ath = ath.replace(/\s'comma'/g, ',')
                        }

                        let split_authors = ath.split(",").toString()

                        if (!split_authors.includes(el)) {
                            validResult = false
                            break            // exits the loop early
                        }
                    }
                }
            }

            return validResult;
        }

    } */

    // ACTUAL Search filter
    const validResultSortingHandler = (obj, element) => {
        const postAcfData = element.acf;
    
        if (Object.keys(postAcfData).length === 0) {
            return false;
        }
    
        const { year, authors, forest_type, species_type, climate_zone, aspect } = postAcfData;
    
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                const searchValue = obj[key]; // The value you're looking for
    
                if (key === "startYear" || key === "endYear") {
                    const postYear = Number(year);
    
                    if (!searchValue || isNaN(postYear) || 
                        (key === "startYear" && Number(searchValue) > postYear) || 
                        (key === "endYear" && Number(searchValue) < postYear)) {
                        return false;
                    }
                } else if (["forest_type", "species_type", "climate_zone", "aspect"].includes(key)) {
                    // Handle array types
                    const fieldValue = postAcfData[key];
                    const stringifiedFieldValue = Array.isArray(fieldValue) ? fieldValue.join() : fieldValue;
    
                    if (!searchValue.every(value => stringifiedFieldValue.includes(value))) {
                        return false;
                    }
                } else if (key === "authors") {
                    let modifiedAuthors = authors.includes('comma') ? authors.replace(/\s'comma'/g, ',') : authors;
                    let splitAuthors = modifiedAuthors.split(",").map(author => author.trim());
    
                    if (!splitAuthors.includes(searchValue)) {
                        return false;
                    }
                }
            }
        }
    
        return true;
    }; 
    /* */

    // Search Functionality {}
    const filterResult = (queryObject) => {

        setNumberOfResultsShowing(25)

        setLoadingPosts(true);

        setTimeout(() => {

            let validResults = []; // Temp storage array
            let referenceArrayOfPostItems = [...posts]; // Copying the posts array

            const { keyword } = queryObject;

            // Performing full text search
            if (keyword) {
                // Setting the reference posts to be the return value from fullTextSearch
                referenceArrayOfPostItems = fullTextSearch(keyword);
            }

            // Getting the selected options from the query
            let queryFilteredQuery = {}
            for (const key in queryObject) {
                if (Object.hasOwnProperty.call(queryObject, key)) {
                    const identifier = key;
                    const queryObjectVal = queryObject[identifier];

                    if (queryObjectVal && (queryObjectVal.length > 0 || queryObjectVal > 0)) {
                        queryFilteredQuery[identifier] = queryObjectVal
                    }
                }
            }

            // Getting the keys of the object that has values. -> { "startYear": 2000, "endYear": 2005, "climate_zone": [ "Equatorial" ] }
            const queryObjectLength = Object.keys(queryFilteredQuery).length;

            if (queryObjectLength > 0) {
                for (let i = 0; i < referenceArrayOfPostItems.length; i++) {
                    const element = referenceArrayOfPostItems[i];

                    if (validResultSortingHandler(queryFilteredQuery, element)) {
                        validResults.push(element)
                    }
                }
            } else {
                validResults = posts;
            }

            const sorted_posts_based_on_title = mergeSort(validResults)
            setRefPosts(sorted_posts_based_on_title)

            let tutles = []
            sorted_posts_based_on_title.forEach(a => {
                tutles.push(return_authors_string(a.title.rendered))
            })

            showingResults = 10

            setLoadingPosts(false);

        }, 500)

    }

    const fullTextSearch = (value) => {
        const ftsResults = fuse.search(value);
        let refArray = [];

        if (ftsResults.length > 0) {
            ftsResults.forEach(res => {
                refArray.push(res.item);
            });
            // setRefPosts(refArray);
        } else {
            setRefPosts([]);
        }

        return refArray;
    }

    // Animation on load
    const elementsToAnimate = document.querySelectorAll('.result-container');
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.classList.add('animate');
                    // Once the animation is triggered, we don't need to observe it anymore.
                    observer.unobserve(entry.target);
                }, 100)
            }
        });
    });
    elementsToAnimate.forEach((element) => {
        observer.observe(element);
    });


    /** 
     * Helper functions
    */
    function mergeSort(arr) {
        if (arr.length <= 1) {
            return arr;
        }

        const middle = Math.floor(arr.length / 2);
        const left = arr.slice(0, middle);
        const right = arr.slice(middle);

        return merge(mergeSort(left), mergeSort(right));
    }

    function merge(left, right) {
        let result = [];
        let leftIndex = 0;
        let rightIndex = 0;

        while (leftIndex < left.length && rightIndex < right.length) {
            if (left[leftIndex].title.rendered < right[rightIndex].title.rendered) {
                result.push(left[leftIndex]);
                leftIndex++;
            } else {
                result.push(right[rightIndex]);
                rightIndex++;
            }
        }

        return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
    }

    const scrollTopTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const incrementResultCount = () => {
        if (numberOfResultsShowing > refPosts.length) {
            return
        } else {
            setNumberOfResultsShowing((prevState) => prevState += showingResults)
            let showingRefPostsArray = [];

            for (let index = 0; index < numberOfResultsShowing; index++) {
                if (index < refPosts.length) {
                    const element = refPosts[index];
                    showingRefPostsArray.push(element)
                }
            }

        }
    }

    const resetPostHandler = (postsLoading) => {

        setNumberOfResultsShowing(25)

        if (postsLoading) {
            setPostsLoading(postsLoading)
            setRefPosts(posts)
        } else {
            setPostsLoading(postsLoading)
        }
    }

    return (
        <React.Fragment>
            {/* <img className='banner-image' src="https://fwpa.com.au/wp-content/uploads/2022/07/Banner-04.jpg" /> */}
            <Container className='full-width-container'>
                <div className="banner-container">
                    <h1>Publications Search Filter</h1>
                </div>

                <Grid container item={true}>
                    <Grid container style={{
                        width: "100%",
                        margin: "0 auto",
                        paddingTop: "2%",
                        background: "#fcfbfd"
                    }} item={true}>
                        <Search
                            fetchingForParent={setFetchedPostData} // setting posts for parent element
                            filterResults={filterResult}  // filterinng function
                            loading={setPostsLoading}
                            resetPosts={resetPostHandler}
                            propRefPosts={refPosts}
                        ></Search>
                    </Grid>
                </Grid>

                <div id='main-results-displayed-container'>


                    <Grid container style={{ background: "#fafafa" }} item={true}>
                        <Grid item xs={12}>
                            {loadingPosts ? <LoadingSpinner></LoadingSpinner> : ''}
                        </Grid>
                        {refPosts.length > 0 && !loadingPosts ? refPosts.map((post, index) => {

                            if (index < numberOfResultsShowing) {
                                return (
                                    <div className={index > 3 ? 'result-container' : 'fw-container'} key={post.id}>
                                        <Grid style={{
                                            width: "95%",
                                            margin: "0 auto",
                                            padding: "1% 0 0 0"
                                        }} item={true}>
                                            <Results
                                                number={index + 1}
                                                date={post.acf.year}
                                                title={post.title.rendered}
                                                authors={post.acf.authors}
                                                year={post.acf.year.slice(0, 4)}
                                                source={post.acf.source}
                                                linkTo={post.id}
                                                forest={post.acf.forest_type}
                                                species={post.acf.species_type}
                                                climate={post.acf.climate_zone}
                                                aspect={post.acf.aspect}
                                            ></Results>
                                        </Grid>
                                    </div>)
                            }


                        }) : ''}
                        {refPosts.length === 0 && !loadingPosts ? <Grid style={{ margin: "2.5% 5%" }}><h1 style={{}}>No results found!</h1></Grid> : ''}
                    </Grid>

                </div>
                <Grid container>
                    <Grid item xs={12} sx={{
                        marginTop: "2em",
                        marginBottom: "3em"
                    }}>
                        {
                            refPosts.length > 0 && numberOfResultsShowing < refPosts.length ? <React.Fragment>
                                <div className="results-to-display">
                                    <Button variant="outlined" onClick={incrementResultCount} color="success">Load more results</Button> <span style={{ marginLeft: "10px" }}>Showing {numberOfResultsShowing} / {refPosts.length}</span>
                                </div>
                            </React.Fragment> : ''
                        }
                    </Grid>
                </Grid>

                {showScrollToTopButton && <button id='scrollToTopButton' onClick={scrollTopTop}>&uarr;</button>}
            </Container>
        </React.Fragment>
    )
}

export default Filter
// --- processing