import React from 'react'

const LoadingSpinner = () => {
    return (
        <div className="ripple-bg-container">
            <h1 style={{color: "white"}}>Loading publication database. Please wait...</h1>
            {/* <div id="load">
                <div>G</div>
                <div>N</div>
                <div>I</div>
                <div>D</div>
                <div>A</div>
                <div>O</div>
                <div>L</div>
            </div> */}
            <div className="lds-ripple"><div></div><div></div></div>
        </div>

    )
}

export default LoadingSpinner