import React from "react";
import { useState, forwardRef, useImperativeHandle } from "react";

// checkmarks
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const DropdownSelectUIK = forwardRef((props, ref) => {
    const [dropdownSelected, setDropdownSelected] = useState(false);
    const [selectedDropdown, setSelectedDropdown] = useState([]);

    const dropdowns = props.dropdowns;
    const dataType = props.dataType;

    const setDropdownSelectedHandler = () => {
        setDropdownSelected(!dropdownSelected);
    };

    const passDataToParentHandler = (e, data) => {
        props.parentDropdownDataHandler(e, data);
    };

    // Setting the active checkboxes
    const setActiveDropdown = (e) => {
        const elem = e.target;

        if (elem.checked) {
            // if the dropdown is not empty and the element does not exist in the selected element list
            if (
                selectedDropdown.length > 0 &&
                selectedDropdown.indexOf(elem.value === -1)
            ) {
                setSelectedDropdown([...selectedDropdown, elem.value]);

                if (dataType) {
                    setTimeout(() => {
                        passDataToParentHandler(dataType, [
                            ...selectedDropdown,
                            elem.value,
                        ]);
                    }, 100);
                }
            } else if (
                selectedDropdown.length === 0 &&
                selectedDropdown.indexOf(elem.value === -1)
            ) {
                setSelectedDropdown([...selectedDropdown, elem.value]);

                if (dataType) {
                    passDataToParentHandler(dataType, [elem.value]);
                }
            } else {
                if (dataType) {
                    passDataToParentHandler(dataType, []);
                }
            }
        } else {
            // if the element is in the array
            if (selectedDropdown && selectedDropdown.indexOf(elem.value) !== -1) {
                const elemLocation = selectedDropdown.indexOf(elem.value);
                const splicedArray = selectedDropdown.splice(elemLocation, 1);
                setSelectedDropdown([...selectedDropdown], splicedArray);

                if (dataType) {
                    setTimeout(() => {
                        passDataToParentHandler(
                            dataType,
                            ([...selectedDropdown], selectedDropdown)
                        );
                    }, 100);
                }
            } else {
                if (dataType) {
                    passDataToParentHandler(dataType, []);
                }
            }
        }
    };

    useImperativeHandle(ref, () => ({
        clearState() {
            setSelectedDropdown([]);
        },
    }));

    return (
        <React.Fragment>
            <FormControl sx={{ m: 0.5, width: "100%" }}>
                <InputLabel id={`${dataType}-multiple-checkbox-label`}>{props.title}</InputLabel>
                <Select
                    labelId={`${dataType}-multiple-checkbox-label`}
                    id={`${dataType}-select`}
                    multiple
                    value={selectedDropdown}
                    onChange={setActiveDropdown}
                    input={<OutlinedInput label={props.title} />}
                    renderValue={(selected) => selected.join(', ')}>

                    {dropdowns &&
                        dropdowns.map((dd) => {
                            return (
                                <MenuItem key={dd} value={dd}>
                                    <Checkbox value={dd} onChange={setActiveDropdown} checked={selectedDropdown.indexOf(dd) > -1} />
                                    <ListItemText primary={dd} />
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        </React.Fragment>
    );
});

export default DropdownSelectUIK;
