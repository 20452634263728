import '../styles/App.css';
import Filter from '../components/Filter';

/*window.addEventListener('click', (e) => {
  const target = e.target;
  const classes = target.className;
  const activeDropdowns = document.querySelectorAll(".dropdown.cdd.is-active");

  if (!classes.includes("cdd")) {
    activeDropdowns.forEach(dropdown => {
      dropdown.classList.remove("is-active");
    })
  }
}); */

function App() {
  return (
    <div className="App">
      <Filter></Filter>
    </div>
  );
}

export default App;
