import React, { useEffect } from 'react';
import { useRef, useState, memo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';

const Timeline = memo((props) => {

    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);

    let startDateRef = useRef();
    let endDateRef = useRef();

    // Creating date range
    let startDateRange = [];
    let endDateRange = [];

    let refStartDate = Number(props.startYear) - 2
    let refEndDate = Number(props.endYear) + 2
    for (var i = refStartDate; i <= refEndDate; ++i) startDateRange.push(i);
    for (var j = refStartDate; j <= refEndDate; ++j) endDateRange.push(j);

    const dateSelectionHandler = (e) => {
        const target = e.target;
        const datasetData = target.name;


        // left, should be smaller
        if (datasetData === "startyear") {

            props.queryYear("startYear", Number(target.value))
            setStartDate(Number(target.value))

            if (endDateRef.current.value < target.value) {
                endDateRef.current.value = null;
                setEndDate(endDateRef.current.value)
            } else {
                setEndDate(endDateRef.current.value)
            }
        }

        // should be greater
        if (datasetData === "endyear") {

            props.queryYear("endYear", Number(target.value))
            setEndDate(Number(target.value))

            if (startDateRef.current.value > target.value) {
                startDateRef.current.value = null;
                setStartDate(startDateRef.current.value)
            } else {
                setStartDate(startDateRef.current.value)
            }
        }

    }

     
    useEffect(() => {
        setStartDate(null)
        setEndDate(null)
    }, [props.selectValues])

    return (
        <React.Fragment>
            <Grid item={true} xs={6} md={4}>

                <FormControl fullWidth={true} sx={{ m: 0.5 }}>
                    <InputLabel id="start-year-select-label">Start Year</InputLabel>
                    <Select
                        labelId="start-year-select-label"
                        id="startYear-simple-select"

                        value={startDate ? startDate : 0}
                        name="startyear"
                        inputRef={startDateRef}
                        label="Start-Year"
                        onChange={dateSelectionHandler}
                    >
                        <MenuItem defaultChecked value={0}>
                            <em>None</em>
                        </MenuItem>
                        {startDateRange && startDateRange.reverse().map((date, i) => {
                            return <MenuItem key={"start" + date + i} value={date}>{date}</MenuItem>
                        })}
                    </Select>
                </FormControl>

            </Grid>
            <Grid  item={true} xs={6} md={4}>

                <FormControl fullWidth={true} sx={{ m: 0.5 }}>
                    <InputLabel id="end-year-select-label">End Year</InputLabel>
                    <Select
                        labelId="end-year-select-label"
                        id="endYear-simple-select"
                        value={endDate ? endDate : 0}
                        label="End-Year"
                        onChange={dateSelectionHandler}
                        name="endyear"
                        inputRef={endDateRef}
                    >
                        <MenuItem defaultChecked value={0}>
                            <em>None</em>
                        </MenuItem>
                        {endDateRange && endDateRange.reverse().map((date, i) => {
                            return <MenuItem key={"end" + date + i} value={date}>{date}</MenuItem>
                        })}
                    </Select>
                </FormControl>

            </Grid>
        </React.Fragment>
    )
})

export default Timeline